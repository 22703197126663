{
  "name": "moje-gwo-fe-new",
  "version": "1.4.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build:test": "ng build --configuration test",
    "build:dev": "ng build --configuration develop",
    "watch": "ng build --watch --configuration test",
    "test": "ng test",
    "test-ci": "ng test --no-watch --no-progress  --code-coverage --browsers=GitlabHeadlessChrome"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.1.2",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/material": "^17.1.2",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@ngrx/effects": "^17.1.1",
    "@ngrx/store": "^17.1.1",
    "@ngrx/store-devtools": "^17.1.1",
    "@sentry/angular": "^8.37.1",
    "http-method-enum": "^1.0.0",
    "jquery": "^3.7.1",
    "lighthouse": "^12.2.2",
    "ng-http-loader": "^15.0.0",
    "ngx-cookie-service": "^17.1.0",
    "ngx-infinite-scroll": "^17.0.1",
    "ngx-loading": "^17.0.0",
    "ngx-slick-carousel": "^17.0.0",
    "rxjs": "~7.8.0",
    "slick-carousel": "^1.8.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.1",
    "@angular/cli": "^17.1.1",
    "@angular/compiler-cli": "^17.1.0",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "typescript": "~5.3.2"
  }
}
